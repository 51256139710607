import React from "react";

const ShippingDelivery = () => {
  return (
    <div className="container mt-4">
      <h2>Shipping & Delivery Policy</h2>
      <p>Coming Soon...</p>
    </div>
  );
};

export default ShippingDelivery;
