import { Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import AddProduct from "../Pages/AddProduct";
import ViewProducts from "../Pages/ViewProducts";
import Categories from "../Pages/Categories";
import SubCategories from "../Pages/SubCategories";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="add-product" element={<AddProduct />} />
      <Route path="ViewProducts" element={<ViewProducts />} />
      <Route path="categories" element={<Categories />} />
      <Route path="subcategories" element={<SubCategories />} />
    </Routes>
  );
};

export default AdminRoutes;
