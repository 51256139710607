import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Form, Button, Nav, Badge, InputGroup } from "react-bootstrap";
import { FaSearch, FaEllipsisV, FaFileExport, FaPlus } from "react-icons/fa";
import "./ViewProducts.css"; // ✅ Import Styles

const ViewProducts = () => {

    const navigate = useNavigate();

    const [products] = useState([
        {
            id: 1,
            name: "Fitbit Sense Advanced Smartwatch",
            category: "Plants",
            tags: ["HEALTH", "EXERCISE", "DISCIPLINE", "LIFESTYLE", "FITNESS"],
            vendor: "Blue Olive Plant sellers. Inc",
            publishedOn: "Nov 12, 10:45 PM",
            image: "https://prium.github.io/phoenix/v1.21.0/assets/img//products/1.png",
        },
        {
            id: 2,
            name: "iPhone 13 Pro Max - Pacific Blue",
            category: "Furniture",
            tags: ["CLASS", "CAMERA", "DISCIPLINE", "PRO", "SWAG"],
            vendor: "Beatrice Furnitures",
            publishedOn: "Nov 11, 7:36 PM",
            image: "https://prium.github.io/phoenix/v1.21.0/assets/img//products/2.png",
        },
        {
            id: 3,
            name: "Apple MacBook Pro 13 M1",
            category: "Plants",
            tags: ["EFFICIENCY", "HANDY", "APPLE", "CREATIVITY", "GRAY"],
            vendor: "PlantPlanet",
            publishedOn: "Nov 11, 8:16 AM",
            image: "https://prium.github.io/phoenix/v1.21.0/assets/img//products/3.png",
        },
    ]);

    return (
        <div className="view-products">
            {/* Top Navigation Tabs */}
            <Nav variant="tabs" defaultActiveKey="all">
                <Nav.Item>
                    <Nav.Link eventKey="all">
                        All <Badge bg="light" text="dark">68817</Badge>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="published">
                        Published <Badge bg="light" text="dark">70348</Badge>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="drafts">
                        Drafts <Badge bg="light" text="dark">17</Badge>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="discount">
                        On Discount <Badge bg="light" text="dark">810</Badge>
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            {/* Search & Filters */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="top-bar">
                    <InputGroup className="search-box">
                        <InputGroup.Text><FaSearch /></InputGroup.Text>
                        <Form.Control type="text" placeholder="Search products" />
                    </InputGroup>

                    <Form.Select className="filter-dropdown">
                        <option>Category</option>
                        <option>Plants</option>
                        <option>Furniture</option>
                        <option>Fashion</option>
                    </Form.Select>

                    <Form.Select className="filter-dropdown">
                        <option>Sub Category</option>
                        <option>Plants</option>
                        <option>Furniture</option>
                        <option>Fashion</option>
                    </Form.Select>

                    <Form.Select className="filter-dropdown">
                        <option>Vendor</option>
                        <option>Blue Olive</option>
                        <option>PlantPlanet</option>
                        <option>Kizzstore</option>
                    </Form.Select>
                </div>

                <div className="top-bar-buttons">
                    <Button variant="outline-secondary"><FaFileExport /> Export</Button>
                    <Button onClick={() => navigate("/admin/add-product")} variant="primary"><FaPlus /> Add Product</Button>
                </div>
            </div>

            {/* Product Table */}
            <Table bordered className="product-table">
                <thead>
                    <tr>
                        <th><Form.Check type="checkbox" /></th>
                        <th>Product Name</th>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Vendor</th>
                        <th>Published On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td><Form.Check type="checkbox" /></td>
                            <td>
                                <div className="product-info">
                                    <img src={product.image} alt={product.name} className="product-img" />
                                    {product.name}
                                </div>
                            </td>
                            <td>{product.category}</td>
                            <td>{product.category}</td>
                            <td>{product.vendor}</td>
                            <td>{product.publishedOn}</td>
                            <td style={{ textAlign: 'center' }}>
                                <FaEllipsisV className="icon actions" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ViewProducts;