import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Form, Button, Nav, Badge, InputGroup } from "react-bootstrap";
import { FaSearch, FaEllipsisV, FaFileExport, FaPlus } from "react-icons/fa";
import "./SubCategories.css"; // ✅ Import Styles

const SubCategories = () => {

    const navigate = useNavigate();

    const [products] = useState([
        
    ]);

    return (
        <div className="view-products">
            {/* Top Navigation Tabs */}
            <Nav variant="tabs" defaultActiveKey="all">
                <Nav.Item>
                    <Nav.Link eventKey="all">
                        All <Badge bg="light" text="dark">68817</Badge>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Glancein">
                        Vendor Specific <Badge bg="light" text="dark">70348</Badge>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Vendor Specific">
                        Drafts <Badge bg="light" text="dark">17</Badge>
                    </Nav.Link>
                </Nav.Item>
            </Nav>

            {/* Search & Filters */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="top-bar">
                    <InputGroup className="search-box">
                        <InputGroup.Text><FaSearch /></InputGroup.Text>
                        <Form.Control type="text" placeholder="Search Sub Categories" />
                    </InputGroup>

                    <Form.Select className="filter-dropdown">
                        <option>Category</option>
                        <option>Blue Olive</option>
                        <option>PlantPlanet</option>
                        <option>Kizzstore</option>
                    </Form.Select>
                </div>

                <div className="top-bar-buttons">
                    <Button variant="outline-secondary"><FaFileExport /> Export</Button>
                    <Button onClick={() => navigate("/admin/add-product")} variant="primary"><FaPlus /> Add Sub Categories</Button>
                </div>
            </div>

            {/* Product Table */}
            <Table bordered className="product-table">
                <thead>
                    <tr>
                        <th><Form.Check type="checkbox" /></th>
                        <th>Display Name</th>
                        <th>Category</th>
                        <th>Display Order</th>
                        <th>Vendor</th>
                        <th>Published On</th>
                        <th>Products Associated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td><Form.Check type="checkbox" /></td>
                            <td>
                                <div className="product-info">
                                    <img src={product.image} alt={product.name} className="product-img" />
                                    {product.name}
                                </div>
                            </td>
                            <td>{product.category}</td>
                            <td>{product.category}</td>
                            <td>{product.vendor}</td>
                            <td>{product.publishedOn}</td>
                            <td style={{ textAlign: 'center' }}>
                                <FaEllipsisV className="icon actions" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default SubCategories;