import React from "react";
import SliderSection from "../Slider/Slider";
import Categories from "../Categories/Categories";
import Products from "./Products";

const Home = () => {
    return (
        <>
            <SliderSection />
            <Categories />
            <Products />
        </>
    );
};

export default Home;
