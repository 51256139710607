import React from "react";

const TermsConditions = () => {
  return (
    <div className="container mt-4">
      <h1 className="mb-4">Terms & Conditions</h1>
      <p><strong>Last updated on Jan 23rd, 2025</strong></p>

      <p>
        For the purpose of these Terms and Conditions, the terms "we", "us", "our" refer to GLANCEIN ENTERPRISES PRIVATE LIMITED, whose registered/operational office is:
      </p>
      <address>
        H.NO.221, VASANTH VIHAR, VIZIANAGARAM RAILWAY STATION<br />
        Vizianagaram, ANDHRA PRADESH, 535003
      </address>

      <p>
        "You", "your", "user", "visitor" refer to any natural or legal person visiting our website and/or agreeing to purchase from us.
      </p>

      <h5>Your use of the website and/or purchase from us are governed by the following Terms and Conditions:</h5>
      <ul>
        <li>The content of the pages of this website is subject to change without notice.</li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable.</li>
        <li>
          This website contains material that is owned by or licensed to us. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
        </li>
        <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
        <li>You may not create a link to our website from another website or document without our prior written consent.</li>
        <li>Any dispute arising out of the use of our website and/or purchase with us is subject to the laws of India.</li>
      </ul>
    </div>
  );
};

export default TermsConditions;
