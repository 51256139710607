const theme = {
    colors: {
        primary: '#109a69',
        accent: '#03dac4',
        background: '#ffffff',
        surface: '#ffffff',
        text: '#000000',
    },

};

export default theme;