import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css"; // Create a CSS file for styling
import { FaPhone } from "react-icons/fa6";
import theme from "../../theme";

const Login = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // React Router navigation hook

  const handleLogin = (e) => {
    e.preventDefault();

    if (username === "admin" && password === "admin123") {

      localStorage.setItem("isAuthenticated", "true");


      navigate("/admin/dashboard");

      window.location.reload();
    } else {
      alert("Invalid credentials!");
    }
  };


  return (
    <Container fluid className="login-container">
      <Row className="login-row">
        {/* Left Side - Banner */}
        <Col md={6} className="login-banner" style={{ backgroundColor: theme.colors.primary }}>
          <div className="banner-content">
            <h2>Simplify your online business</h2>
            <p>Tasya and Xain</p>
            <span>Founder, Flo and Wer shop</span>
            <div className="navigation-dots">
              <span className="active-dot"></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </Col>

        {/* Right Side - Login Form */}
        <Col md={6} className="login-form-container">
          <div className="login-form">
            <h3 style={{ textAlign: 'center', marginBottom: 20 }}>Welcome back</h3>

            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-3">
                <Form.Label>Username / Email</Form.Label>
                <Form.Control type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required placeholder="Your name" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required placeholder="Enter your password" />
              </Form.Group>

              <Link className="forgot-password">Forget password?</Link>

              <Button style={{ backgroundColor: theme.colors.primary }} className="signin-btn" type="submit">
                Sign In
              </Button>

              <div className="separator">or</div>

              <Button variant="light" className="social-btn google-btn">
                <FaPhone style={{ marginRight: 20 }} /> Sign In with Mobile
              </Button>
            </Form>

            <div className="footer-links">
              <Link >Customer Support</Link>
              <Link >Terms of Service</Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
