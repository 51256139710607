import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import "./Header.css";
import UserDropdown from "./UserProfileDropdown";

const Header = () => {
  return (
    <Navbar fixed="top" bg="white" expand="lg" className="header shadow-sm px-4">
      <Nav className="ms-auto">
        <Nav.Link><FaBell /></Nav.Link>
        <Nav.Link>
          <UserDropdown></UserDropdown>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default Header;