import React, { useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import theme from "../../theme";

const ProductDetails = () => {
    const [selectedSize, setSelectedSize] = useState("M");
    const [quantity, setQuantity] = useState(0); // ✅ Track quantity

    const productImages = [
        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-collection-1_large.png?v=1530129113",
        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-collection-1_large.png?v=1530129113",
        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-collection-1_large.png?v=1530129113",
        "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-collection-1_large.png?v=1530129113",
    ];

    // Slick slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    const handleAddToCart = () => {
        setQuantity(1); // ✅ Set to 1 when "ADD" is clicked
    };

    const handleIncrement = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecrement = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 0));
    };

    return (
        <div className="container mt-5">
            {/* Breadcrumb */}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item"><a href="/products">Women - Tops</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Light Gray Top</li>
                </ol>
            </nav>

            {/* Product Details Section */}
            <div className="row">
                {/* Product Images - Slider */}
                <div className="col-md-6">
                    <Slider {...sliderSettings}>
                        {productImages.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Product ${index}`} className="img-fluid rounded" />
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* Product Info */}
                <div className="col-md-6">
                    <h5 className="text-muted">MINIMAL WOMEN COLLECTION</h5>
                    <h2 className="fw-bold">Light Gray Top for Women</h2>
                    <p>
                        <del className="text-muted">₹50.00</del>
                        <span style={{ color: theme.colors.primary, fontSize: 20, fontWeight: 800 }}> ₹40</span>
                    </p>

                    {/* Available Sizes */}
                    <h6 className="mt-3">Available Options</h6>
                    <div className="d-flex">
                        {["S", "M", "L", "XL", "XXL"].map((size) => (
                            <button
                                key={size}
                                className={`btn btn-outline-dark me-2 ${selectedSize === size ? "active" : ""}`}
                                onClick={() => setSelectedSize(size)}
                            >
                                {size}
                            </button>
                        ))}
                    </div>

                    {/* Wishlist & Add to Cart */}
                    <div className="mt-4" style={{width:200}}>
                        {quantity === 0 ? (
                            <button
                                className="btn w-100"
                                style={{
                                    background: theme.colors.primary,
                                    borderColor: theme.colors.primary,
                                    color: "white",
                                    fontWeight: 600,
                                    height: "45px",
                                }}
                                onClick={handleAddToCart}
                            >
                                <FaShoppingCart className="me-2" /> Add to Cart
                            </button>
                        ) : (
                            <div className="d-flex align-items-center justify-content-between border p-2 rounded bg-light" style={{ height: "45px" }}>
                                <button className="btn btn-sm btn-outline-success" onClick={handleDecrement} style={{ width: "40px", height: "40px" }}>−</button>
                                <span className="fw-bold" style={{ width: "40px", textAlign: "center" }}>{quantity}</span>
                                <button className="btn btn-sm btn-outline-success" onClick={handleIncrement} style={{ width: "40px", height: "40px" }}>+</button>
                            </div>
                        )}
                    </div>

                    {/* Product Description */}
                    <div className="mt-4">
                        <h5>PRODUCT DETAILS</h5>
                        <p>Light Gray solid Top, has a boat neck, 3/4 sleeves</p>
                        <h6>Material & Care</h6>
                        <ul>
                            <li>Cotton</li>
                            <li>Machine-wash</li>
                        </ul>
                        <h6>Sold By:</h6>
                        <p>Wind & Store, Stillwater</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
