import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";

// Dummy product categories
const productCategories = [
  {
    title: "Dairy, Bread & Eggs",
    products: [
      { name: "Amul Taaza Toned Fresh Milk", price: "₹28", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143" },
      { name: "Heritage Daily Health Milk", price: "₹31", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585" },
      { name: "Amul Gold Full Cream Milk", price: "₹35", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142" },
      { name: "Godrej Jersey Curd", price: "₹47", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111" },
      { name: "Amul Salted Butter", price: "₹60", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314" },
    ],
  },
  {
    title: "Rolling Paper & Tobacco",
    products: [
      { name: "Ultimate Rolling Paper", price: "₹90", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/478728a.jpg?ts=1690814280" },
      { name: "Brown Ripper Rolling Paper", price: "₹120", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/506375a.jpg?ts=1698309252" },
      { name: "White Party Cone Paper", price: "₹80", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/496996a.jpg?ts=1690814374" },
      { name: "Perfect Rolling Paper (Pink)", price: "₹31", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/c0341810-89eb-431d-85d5-c87c458f2454.jpg" },
      { name: "King Size Rolling Paper", price: "₹50", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/512860a.jpg?ts=1690832312" },
    ],
  },
];

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Products = () => {
  return (
    <div className="container mt-4">
      {productCategories.map((category, index) => (
        <div key={index} className="mb-4">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4>{category.title}</h4>
            <Link href="#" className="text-success fw-bold">See All</Link>
          </div>
          <Slider {...sliderSettings}>
            {category.products.map((product, i) => (
              <div key={i} className="p-2">
                <ProductCard product={product} />
              </div>
            ))}
          </Slider>
        </div>
      ))}
    </div>
  );
};

export default Products;