import React, { useState } from "react";
import { Form, Button, Row, Col, Card, InputGroup } from "react-bootstrap";
import { FaUpload, FaSave, FaDraftingCompass } from "react-icons/fa";
import "./AddProduct.css";

const AddProduct = () => {
  const [productDetails, setProductDetails] = useState({
    title: "",
    description: "",
    category: "Men's Clothing",
    vendor: "Men's Clothing",
    collection: "Collection",
    tags: "Men's Clothing",
    regularPrice: "",
    salePrice: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setProductDetails({ ...productDetails, image: file });
  };

  return (
    <div className="add-product-container">
      <Row>
        <Col md={8}>
          {/* Product Title & Description */}
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Product Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Write title here..."
                      name="title"
                      value={productDetails.title}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <Form.Label>Product Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Write a description here..."
                      name="description"
                      value={productDetails.description}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Product Type</Form.Label>
                    <Form.Select>
                      <option>Single</option>
                      <option>Group</option>
                      <option>Configurable</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Image Upload */}
          <Card className="mb-3">
            <Card.Body>
              <Form.Group>
                <Form.Label>Display Images</Form.Label>
                <div className="image-upload-box">
                  <input type="file" onChange={handleImageUpload} className="image-input" />
                  <FaUpload className="upload-icon" />
                  <p>Drag your photo here or <span className="upload-link">Browse from device</span></p>
                </div>
              </Form.Group>
            </Card.Body>
          </Card>

          {/* Pricing & Inventory */}
          <Card className="mb-3">
            <Card.Body>
              <h5>Inventory</h5>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Regular Price</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="Regular price"
                        name="regularPrice"
                        value={productDetails.regularPrice}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Sale Price</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="Sale price"
                        name="salePrice"
                        value={productDetails.salePrice}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Right Sidebar Options */}
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <h5>Organize</h5>

              <Form.Group >
                <Form.Label>Vendor</Form.Label>
                <Form.Select>
                  <option>Men's Clothing</option>
                  <option>PlantPlanet</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Category</Form.Label>
                <Form.Select>
                  <option>Men's Clothing</option>
                  <option>Women's Clothing</option>
                  <option>Furniture</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mt-3">
                <Form.Label>Sub   Category</Form.Label>
                <Form.Select>
                  <option>Men's Clothing</option>
                  <option>Women's Clothing</option>
                  <option>Furniture</option>
                </Form.Select>
              </Form.Group>

            </Card.Body>
          </Card>

        </Col>
      </Row>

      {/* Action Buttons */}
      <div className="action-buttons">
        <Button variant="light">Discard</Button>
        <Button variant="secondary" className="mx-2"><FaSave /> Save Draft</Button>
        <Button variant="primary"><FaDraftingCompass /> Publish Product</Button>
      </div>
    </div>
  );
};

export default AddProduct;