import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaUser, FaEdit, FaExchangeAlt, FaSignOutAlt } from "react-icons/fa";
import "./UserProfileDropdown.css"; // ✅ Import Styles

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown className="user-dropdown" show={isOpen} onToggle={() => setIsOpen(!isOpen)}>
      <Dropdown.Toggle variant="light" className="profile-btn">
        <img src="https://www.nobleui.com/laravel/template/demo1/assets/images/faces/face1.jpg" alt="User" className="profile-img" />
        User Name
      </Dropdown.Toggle>

      <Dropdown.Menu align="end" className="dropdown-menu" style={{marginTop:15}}>
        {/* Profile Section */}
        <div className="profile-info">
          <img src="https://www.nobleui.com/laravel/template/demo1/assets/images/faces/face1.jpg" alt="User" className="profile-img-lg" />
          <h6>Amiah Burton</h6>
          <p>amiahburton@gmail.com</p>
        </div>

        <Dropdown.Divider />

        {/* Menu Options */}
        <Dropdown.Item href="/profile">
          <FaUser className="icon" /> Profile
        </Dropdown.Item>
        <Dropdown.Item href="/edit-profile">
          <FaEdit className="icon" /> Edit Profile
        </Dropdown.Item>
        <Dropdown.Item href="/switch-user">
          <FaExchangeAlt className="icon" /> Switch User
        </Dropdown.Item>
        <Dropdown.Item href="/logout">
          <FaSignOutAlt className="icon logout" /> Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
