import React from "react";
import ProductCard from "./ProductCard";

const CategorySubcategoryWiseProducts = ({ selectedCategory, selectedSubCategory }) => {
  const allProducts = [
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
    { category: "Fresh Vegetables", subcategory: "Leafy Greens", name: "Spinach", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/5ee4441d-9109-48fa-9343-f5ce82b905a6.jpg?ts=1706182143", price: "₹30", discount: "10% OFF", oldPrice: "₹35" },
    { category: "Fresh Vegetables", subcategory: "Root Vegetables", name: "Carrot", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/2611ae97-7654-4ca6-b726-ac1c23ff006a.jpg?ts=1712325585", price: "₹20", discount: "5% OFF", oldPrice: "₹25" },
    { category: "Fresh Fruits", subcategory: "Citrus Fruits", name: "Orange", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/1c0db977-31ab-4d8e-abf3-d42e4a4b4632.jpg?ts=1706182142", price: "₹40", discount: "12% OFF", oldPrice: "₹45" },
    { category: "Fresh Fruits", subcategory: "Berries", name: "Strawberry", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/images/products/sliding_image/342271a.jpg?ts=1687525111", price: "₹60", discount: "20% OFF", oldPrice: "₹75" },
    { category: "Mangoes & Melons", subcategory: "Mangoes", name: "Alphonso Mango", img: "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=270/app/assets/products/sliding_images/jpeg/0be0d49a-4dae-408a-8786-afae1dd05cb1.jpg?ts=1707312314", price: "₹80", discount: "15% OFF", oldPrice: "₹95" },
  ];

  // Filter products based on selected category & subcategory
  let filteredProducts = allProducts.filter(product => product.category === selectedCategory);
  
  if (selectedSubCategory) {
    filteredProducts = filteredProducts.filter(product => product.subcategory === selectedSubCategory);
  }

  return (
    <div className="col-md-9">
     
      
      <div className="row">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <ProductCard product={product} />
            </div>
          ))
        ) : (
          <p className="text-center">No products available in this category.</p>
        )}
      </div>
    </div>
  );
};

export default CategorySubcategoryWiseProducts;
