import React, { useState } from "react";
import theme from "../../theme";
import { Link } from "react-router-dom";

const ProductCard = ({ product }) => {

  const [quantity, setQuantity] = useState(0); // ✅ Track quantity per product

  const handleAddToCart = () => {
    setQuantity(1); // ✅ Set to 1 when "ADD" is clicked
  };

  const handleIncrement = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 0));
  };

  return (
    <div className="card border-0 shadow-sm">
      {product.discount && (
        <div className="position-absolute text-white px-2 py-1" style={{ top: 10, left: 10, fontSize: "12px", borderRadius: "5px", backgroundColor: theme.colors.primary }}>
          {product.discount}
        </div>
      )}
      <div className="card-body">
        <Link to="/ProductDetails" style={{ textDecoration: "none", color: "inherit" }}>
          <img src={product.img} className="card-img-top" alt={product.name} />
          <h6 className="card-title" style={{
            fontSize: 14,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}>{product.name}
          </h6>
          <p style={{ textAlign: 'left', fontSize: 12, fontWeight: 600, color: 'grey' }}>500g</p>
          <p className="text-muted">
            {product.oldPrice && <del>{product.oldPrice}</del>}{" "}
            <span className="fw-bold text-success">{product.price}</span>
          </p>
        </Link>

        <div style={{ height: "40px" }}>  {/* ✅ Maintain height */}
          {quantity === 0 ? (
            <button
              className="btn btn-success w-100"
              onClick={handleAddToCart}
              style={{ height: "40px" }}  // ✅ Same height as counter
            >
              ADD
            </button>
          ) : (
            <div className="d-flex align-items-center justify-content-between border p-1 rounded bg-light" style={{ height: "40px" }}>
              <button className="btn btn-sm btn-outline-success" onClick={handleDecrement} style={{ width: "35px", height: "35px" }}>−</button>
              <span className="fw-bold" style={{ width: "35px", textAlign: "center" }}>{quantity}</span>
              <button className="btn btn-sm btn-outline-success" onClick={handleIncrement} style={{ width: "35px", height: "35px" }}>+</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
