import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer className="bg-light p-4 mt-4">
            <div className="container-fluid">
                <div className="text-center mt-4">
                    <p>© GLANCEIN ENTERPRISES PRIVATE LIMITED</p>
                    <h6 className="fw-bold">Download App</h6>
                    <img alt="downloadfromapple" style={{ height: 30, marginRight: 20 }} src="../Images/downloadfromapple.svg"></img>
                    <img alt="downloadfromapple" style={{ height: 30 }} src="../Images/downloadfromgoogle.png"></img>
                    <div className="mt-3">
                        <FaFacebook className="me-3" size={24} />
                        <FaTwitter className="me-3" size={24} />
                        <FaInstagram className="me-3" size={24} />
                        <FaLinkedin className="me-3" size={24} />
                    </div>
                    <div className="mt-3">
                        <Link to="/privacypolicy" style={{ marginRight: 20 }}>Privacy Policy</Link>
                        <Link to="/termsandconditions" style={{ marginRight: 20 }}>Terms & Conditions</Link>
                        <Link to="/cancellationrefund" style={{ marginRight: 20 }}>Cancellation & Refund Policy</Link>
                        <Link to="/shippingdelivery" style={{ marginRight: 20 }}>Shipping & Delivery Policy</Link>
                    </div>
                    <div className="mt-3">
                        <p><FaLocationDot /> Springcowork, B1/639 A, Janakpuri, West Delhi - 110058</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;