import React, { useState } from "react";
import SubCategories from "./Categories/SubCategories";
import CategorySubcategoryWiseProducts from "./components/CategorySubcategoryWiseProducts";
import "bootstrap/dist/css/bootstrap.min.css";

const CategoryWiseProducts = () => {
  const [selectedCategory, setSelectedCategory] = useState("Fresh Vegetables");
  const [selectedSubCategory, setSelectedSubCategory] = useState(""); // New state for subcategory

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        {/* Left Sidebar (Categories) */}
        <SubCategories
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory} />

        {/* Right Side (Products) */}
        <CategorySubcategoryWiseProducts
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
        />
      </div>
    </div>
  );
};

export default CategoryWiseProducts;
