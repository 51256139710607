import "bootstrap/dist/css/bootstrap.min.css";

import UserApp from "./Customer/UserApp";
import AdminApp from "./Admin/AdminApp";

function App() {

  const isAdminRoute = window.location.pathname.startsWith("/admin");

  return isAdminRoute ? <AdminApp /> : <UserApp />;
}

export default App;
