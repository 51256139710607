import React from "react";

const CancellationRefund = () => {
  return (
    <div className="container mt-4">
      <h1 className="mb-4">Cancellation & Refund Policy</h1>
      <p><strong>Last updated on Jan 23rd, 2025</strong></p>

      <p>
        GLANCEIN ENTERPRISES PRIVATE LIMITED believes in assisting its customers to the fullest extent and has therefore adopted a liberal cancellation policy. The following terms apply:
      </p>

      <h5>1. Cancellation Policy</h5>
      <ul>
        <li>Cancellations are considered only if the request is made within 7 days of placing the order. However, if the orders have been communicated to the vendors/merchants and they have initiated the shipping process, the cancellation request may not be entertained.</li>
        <li>Cancellation requests are not accepted for perishable items like flowers or eatables. However, refunds or replacements can be made if the customer establishes that the quality of the product delivered is not satisfactory.</li>
      </ul>

      <h5>2. Refund Policy</h5>
      <ul>
        <li>In case of receipt of damaged or defective items, please report the same to our Customer Service team within 7 days of receipt.</li>
        <li>If the product received is not as shown on the site or does not meet your expectations, report it to our Customer Service team within 7 days. After review, the team will make an appropriate decision.</li>
        <li>For complaints regarding products with a warranty, please refer the issue to the manufacturer.</li>
        <li>Refunds approved by GLANCEIN ENTERPRISES PRIVATE LIMITED will take 6-8 days to process and be credited to you.</li>
      </ul>
    </div>
  );
};

export default CancellationRefund;
