import React from "react";

const Dashboard = () => {
  return (
    <div className="container mt-4">
     Dashboard
    </div>
  );
};

export default Dashboard;