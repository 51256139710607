import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./Header/Header";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsAndConditions";
import CancellationRefund from "./CancellationRefund";
import ShippingDelivery from "./ShippingDelivery";
import ProductDetails from "./components/ProductDetails";
import CategoryWiseProducts from "./CategoryWiseProducts";

function UserApp() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsConditions />} />
        <Route path="/cancellationrefund" element={<CancellationRefund />} />
        <Route path="/shippingdelivery" element={<ShippingDelivery />} />
        <Route path="/ProductDetails" element={<ProductDetails />} />
        <Route path="/CategoryWiseProducts" element={<CategoryWiseProducts />} />
      </Routes>
      <Footer />
    </>

  );
}

export default UserApp;
