import { Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthenticationApp from "./AuthenticationApp";
import MainApp from "./MainApp";

function AdminApp() {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  return (
    <Routes>
      {/* Redirect to login if not authenticated */}
      {!isAuthenticated ? (
        <>
          <Route path="/admin" element={<Navigate to="/admin/login" />} />
          <Route path="/admin/login" element={<AuthenticationApp />} />
          <Route path="*" element={<Navigate to="/admin/login" />} />
        </>
      ) : (
        <>
          <Route path="/admin/*" element={<MainApp />} />
          <Route path="*" element={<Navigate to="/admin/dashboard" />} />
        </>
      )}
    </Routes>
  );
}

export default AdminApp;
