import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaShoppingCart, FaLocationArrow, FaSearch, FaTimes, FaArrowLeft, FaStore } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import theme from '../../theme';

const Header = () => {

    const [showLoginModal, setShowLoginModal] = useState(false);
    //const [showLocationModal, setShowLocationModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    //const [searchQuery, setSearchQuery] = useState("");
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [resendTimer, setResendTimer] = useState(30);
    const otpInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];


    const handleOtpChange = (index, value) => {
        if (!/^[0-9]?$/.test(value)) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 3) {
            otpInputs[index + 1].current.focus();
        }
    };


    return (
        <>
            <div
                className="d-flex justify-content-between align-items-center px-4 py-2"
                style={{
                    background: "linear-gradient(to right, rgb(14 154 105), rgb(14 154 105 / 80%))",
                    color: "#f8f9fa",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderRadius: "0 0 0px 0px",
                }}
            >
                <span>
                    🚀 If you are looking to start your own <span style={{ color: "#fbd739" }}>e-commerce or quick commerce</span> platform similar to ours, we are happy to help you set it up within <span style={{ fontWeight: "800",color:'#fbd739' }}>7 days. </span>
                    *T&C Apply
                </span>
                <button className="btn btn-light btn-sm">Know More</button>
            </div>

            <header className="sticky-top bg-white shadow-sm p-2">
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container-fluid d-flex justify-content-between align-items-center">

                        {/* Left - Logo */}
                        <div className="col-auto">
                            <Link className="navbar-brand fw-bold text-success" to="/">
                                <img src='../logo.png' alt="Logo" style={{ width: 150 }} />
                            </Link>
                        </div>

                        {/* Center - Delivery Address & Search */}
                        <div className="col-md-6 d-flex align-items-center">
                            {/* Location */}
                            <div className="d-flex align-items-center" style={{ width: 300 }}>
                                <FaLocationArrow style={{ color: theme.colors.primary, marginRight: 15, fontSize: 30 }} />
                                <div>
                                    <h6 className="mb-0 fw-bold">Delivering To</h6>
                                    <p className="mb-0 text-muted">Vizianagaram, 535003</p>
                                </div>
                            </div>

                            {/* Search Bar */}
                            <form className="ms-2 position-relative" style={{ width: '100%' }}>
                                <FaSearch className="position-absolute" style={{ top: "50%", left: "15px", transform: "translateY(-50%)", color: "#555" }} />
                                <input
                                    className="form-control rounded-pill ps-5"
                                    type="search"
                                    placeholder='Search products and stores you want...'
                                    aria-label="Search"
                                    style={{ backgroundColor: "#f8f9fa", borderColor: '#ccc', height: "50px" }}
                                />
                            </form>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <button onClick={() => setShowLoginModal(true)} className="btn btn-secondary d-flex align-items-center">
                                <FaStore className="me-2" />
                                Become A Seller
                            </button>
                        </div>
                        {/* Right - User & Cart Icons */}
                        <div className="col-auto d-flex align-items-center">
                            <button onClick={() => setShowLoginModal(true)} style={{ background: theme.colors.primary, borderColor: theme.colors.primary, marginRight: 10 }} className="btn btn-success d-flex align-items-center">
                                <FaUser className="me-2" />
                                Login
                            </button>
                            <button style={{ background: theme.colors.primary, borderColor: theme.colors.primary }} className="btn btn-success d-flex align-items-center">
                                <FaShoppingCart className="me-2" />
                                My Cart
                            </button>
                        </div>
                    </div>
                </nav>
                <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)} centered>
                    <Modal.Body className="text-center">
                        {!isOtpScreen ? (
                            <> {/* Phone Number Input Screen */}
                                <FaTimes className="position-absolute" style={{ top: 15, right: 15, cursor: "pointer" }} onClick={() => setShowLoginModal(false)} />
                                <img src="../logo.png" alt="Logo" style={{ width: 150, marginBottom: 10 }} />
                                <div className='container px-5'>
                                    <p>Log in or Sign up</p>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">+91</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter mobile number"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <button className="btn w-100" style={{ background: theme.colors.primary, color: 'white' }} disabled={!phoneNumber} onClick={() => setIsOtpScreen(true)}>Continue</button>
                                </div>
                                <p className="mt-3 text-muted small">
                                    By continuing, you agree to our <Link>Terms of service</Link> & <Link>Privacy policy</Link>
                                </p>
                            </>
                        ) : (
                            <> {/* OTP Verification Screen */}
                                <FaArrowLeft className="position-absolute" style={{ top: 15, left: 15, cursor: "pointer" }} onClick={() => setIsOtpScreen(false)} />
                                <FaTimes className="position-absolute" style={{ top: 15, right: 15, cursor: "pointer" }} onClick={() => setShowLoginModal(false)} />
                                <h5 className="fw-bold">OTP Verification</h5>
                                <p>We have sent a verification code to</p>
                                <h6 className="fw-bold">+91-{phoneNumber}</h6>
                                <div className="d-flex justify-content-center my-3">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            ref={otpInputs[index]}
                                            type="text"
                                            className="form-control text-center mx-1"
                                            style={{ width: 50, height: 50, fontSize: 24 }}
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                        />
                                    ))}
                                </div>
                                <p className="text-muted">
                                    Resend Code {resendTimer > 0 ? `(in ${resendTimer} secs)` : <Link onClick={setResendTimer}>Resend</Link>}
                                </p>
                            </>
                        )}
                    </Modal.Body>
                </Modal>
            </header>
        </>
    );
};

export default Header;