import React from "react";
import { Container } from "react-bootstrap";
import Sidebar from "./Layout/Sidebar";
import Header from "./Layout/Header";
import AdminRoutes from "./Layout/AdminRoutes";
import "./MainApp.css"; // ✅ Add new styles to fix layout

const MainApp = () => {
  return (
    <div className="main-app">
      {/* Fixed Sidebar */}
      <Sidebar />

      <div className="content">
        {/* Fixed Header */}
        <Header />
        
        {/* Scrollable Main Content */}
        <Container fluid className="main-content">
          <AdminRoutes />
        </Container>
      </div>
    </div>
  );
};

export default MainApp;
