import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

// Temporary Category Data (You can replace images later)
const categories = [
    { name: "Fruits & Vegetables", img: "../Images/fruits-vegetables_3362720.png" },
    { name: "Cold Drinks & Juices", img: "../Images/snacks_17024184.png" },
    { name: "Snacks & Munchies", img: "../Images/soft-drinks_3731076.png" },
    { name: "Breakfast & Instant Food", img: "../Images/fruits-vegetables_3362720.png" },
    { name: "Sweet Tooth", img: "../Images/fruits-vegetables_3362720.png" },
    { name: "Bakery & Biscuits", img: "../Images/fruits-vegetables_3362720.png" },
];

const Categories = () => {
    return (
        <div className="container mt-4">
            <h3 className="text-center mb-4 fw-bold">Shop by Categories</h3>
            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6">
                {categories.map((category, index) => (
                    <div key={index} className="col text-center">
                        <Link to="/CategoryWiseProducts" style={{ textDecoration: "none", color: "inherit" }}>
                            <div className="p-3">
                                <img src={category.img} alt={category.name} className="img-fluid mb-2 rounded" style={{ maxWidth: "80px" }} />
                                <p style={{ fontSize: 12, fontWeight: 700 }} className="mb-0">{category.name}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
