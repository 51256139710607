import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mt-4">
      <h1 className="mb-4">Privacy Policy</h1>
      <p><strong>Last updated on Jan 23rd, 2025</strong></p>

      <p>
        This Privacy Policy explains how **GLANCEIN ENTERPRISES PRIVATE LIMITED** collects, uses, and protects the information you provide while using our website.
      </p>

      <h5>1. Information We Collect</h5>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, and shipping details.</li>
        <li><strong>Payment Details:</strong> Credit/Debit card information (secured and not stored).</li>
        <li><strong>Usage Data:</strong> IP address, browser type, and browsing behavior on our site.</li>
      </ul>

      <h5>2. How We Use Your Information</h5>
      <ul>
        <li>To process and fulfill your orders.</li>
        <li>To provide customer support.</li>
        <li>To personalize your shopping experience.</li>
        <li>To send promotional offers (only with your consent).</li>
      </ul>

      <h5>3. Data Security</h5>
      <p>
        We are committed to ensuring that your information is **secure**. We implement encryption and other security measures to prevent unauthorized access.
      </p>

      <h5>4. Sharing Your Information</h5>
      <p>
        We do not sell or rent your personal data. We only share data with **trusted third parties** (like payment gateways) to complete transactions.
      </p>

      <h5>5. Your Choices</h5>
      <p>
        You have the right to **access, modify, or delete** your personal data at any time. You can also opt-out of promotional emails.
      </p>

      <h5>6. Cookies</h5>
      <p>
        We use cookies to enhance user experience. You can disable cookies in your browser settings.
      </p>

      <h5>7. Changes to This Policy</h5>
      <p>
        We may update this Privacy Policy from time to time. Please review it periodically for changes.
      </p>

      <h5>8. Contact Us</h5>
      <p>
        If you have any questions about this Privacy Policy, contact us at **support@glancein.com**.
      </p>
    </div>
  );
};

export default PrivacyPolicy;