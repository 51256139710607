import React from "react";

const SubCategories = ({ selectedCategory, setSelectedCategory }) => {
  const categories = [
    { name: "Fresh Vegetables", icon: "🥦" },
    { name: "Fresh Fruits", icon: "🍇" },
    { name: "Mangoes & Melons", icon: "🥭" },
    { name: "Seasonal", icon: "🍓" },
    { name: "Exotics", icon: "🫐" },
    { name: "Freshly Cut & Sprouts", icon: "🥗" },
    { name: "Frozen Veg", icon: "❄️" },
    { name: "Leafies & Herbs", icon: "🌿" },
  ];

  return (
    <div className="col-md-3">
      <ul className="list-group">
        {categories.map((category, index) => (
          <li
            key={index}
            className={`list-group-item d-flex align-items-center ${selectedCategory === category.name ? "active" : ""}`}
            onClick={() => setSelectedCategory(category.name)}
            style={{ cursor: "pointer" }}
          >
            <span className="me-2">{category.icon}</span>
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubCategories;
