import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {FaHome, FaFileAlt, FaChevronDown, FaChevronRight} from "react-icons/fa";
import "./Sidebar.css"; 

const Sidebar = () => {
  const [openMenus, setOpenMenus] = useState({
    frontPages: false,
    ecommerce: false,
    academy: false,
  });

  const toggleMenu = (menu) => {
    setOpenMenus({ ...openMenus, [menu]: !openMenus[menu] });
  };

  return (
    <div className="sidebar">
      {/* Sidebar Header */}
      <div className="sidebar-header">
        <div className="logo" style={{paddingLeft:15}}> 
          <span className="logo-text"><img width={70} alt="logo" src="../logo.png"></img></span>
        </div>
      </div>

      <Nav className="flex-column" style={{padding:15}}>
        {/* Dashboard */}
        <Nav.Item>
          <Nav.Link as={Link} to="/admin/dashboard" className="nav-main active">
            <FaHome className="icon" /> <span>Dashboards</span>
          </Nav.Link>
        </Nav.Item>

        {/* Front Pages */}
        <Nav.Item>
          <Nav.Link className="nav-main" onClick={() => toggleMenu("frontPages")}>
            <FaFileAlt className="icon" /> <span>Catelog</span>
            {openMenus.frontPages ? <FaChevronDown className="chevron" /> : <FaChevronRight className="chevron" />}
          </Nav.Link>
          {openMenus.frontPages && (
            <div className="submenu">
              <Nav.Link as={Link} to="/admin/ViewProducts" className="nav-sub">Products</Nav.Link>
              <Nav.Link as={Link} to="/admin/categories" className="nav-sub">Categories</Nav.Link>
              <Nav.Link as={Link} to="/admin/subcategories" className="nav-sub">Sub Categories</Nav.Link>
            </div>
          )}
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;
